// libraries
import React from 'react';
import styled from 'styled-components';
// components
import { Container, Col, Row, BREAKPOINT } from 'components/UI/Grid';
// constants
import { COLORS } from 'constants/settings';

export const Document = ({ data }) => {
    const { title, content } = data;

    return (
        <StyledDocPage>
            <Container>
                <Row>
                    <Col xs={12} md={10} lg={9}>
                        <h1>{title}</h1>
                        <TextDocWysiwyg
                            dangerouslySetInnerHTML={{
                                __html: content,
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </StyledDocPage>
    );
};

const StyledDocPage = styled.section`
    padding-top: 132px;

    h1 {
        margin: 0;
        padding-bottom: 24px;
        font-size: 32px;

        ${BREAKPOINT.lg} {
            font-size: 48px;
        }
    }
`;

const TextDocWysiwyg = styled.div`
    p {
        margin: 16px 0;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.5px;

        ${BREAKPOINT.lg} {
            font-size: 18px;
            letter-spacing: 1px;
        }

        &.underlined {
            text-decoration-line: underline;
        }
    }

    a {
        color: ${COLORS.dark};
        font-weight: 500;
        text-decoration: underline;
        word-break: break-word;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }

    h2 {
        margin: 32px 0 16px;
        font-size: 28px;

        ${BREAKPOINT.lg} {
            margin: 40px 0 16px;
            font-size: 34px;
        }
    }

    h3 {
        margin: 32px 0 16px;
        font-size: 20px;

        ${BREAKPOINT.lg} {
            margin: 40px 0 16px;
            font-size: 28px;
        }
    }

    b,
    strong {
        font-weight: 600;
    }

    figure {
        margin: 24px auto;

        ${BREAKPOINT.lg} {
            margin: 40px auto;
        }
    }

    ul,
    ol {
        li {
            margin: 16px 0;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.5px;

            ${BREAKPOINT.md} {
                margin: 21px 0;
                font-size: 18px;
                letter-spacing: 1px;
            }
        }
    }

    ul {
        padding-left: 15px;
        list-style-type: disc;
    }

    ol.latin,
    ol.roman,
    ol.lowlatin,
    ol.lowroman,
    ul.circle,
    ul.square {
        counter-reset: revert;

        > li::before {
            display: none;
        }
    }

    ul.circle > li {
        list-style-type: circle;
    }

    ul.square > li {
        list-style-type: square;
    }

    ul.hyphen-point > li {
        position: relative;
        padding-left: 15px;
        list-style-type: none;

        ::before {
            position: absolute;
            top: 14px;
            left: 0 !important;
            display: block;
            width: 4px;
            height: 1px;
            background-color: ${COLORS.dark};
            content: '';
        }
    }

    /* big symbols ol list-type */
    ol.latin > li {
        list-style-type: upper-alpha;
    }

    ol.roman > li {
        list-style-type: upper-roman;
    }

    /* small symbols ol list-type */
    ol.lowlatin > li {
        list-style-type: lower-latin;
    }

    ol.lowroman > li {
        list-style-type: lower-roman;
    }

    /* styles for list 1.2, 1.3... */
    ol:not(.exlude-from-nest) {
        padding-left: 44px;
        line-height: 1.3;
        list-style: none;
        counter-reset: li;

        > li {
            position: relative;
        }

        > li::before {
            position: absolute;
            left: -28px;
            line-height: 1.5;
            content: counters(li, '.') '. ';
            counter-increment: li;

            ${BREAKPOINT.lg} {
                left: -36px;
            }
        }

        > li {
            > ol {
                li {
                    ::before {
                        left: -40px;

                        ${BREAKPOINT.lg} {
                            left: -48px;
                        }
                    }

                    > ol {
                        li {
                            ::before {
                                left: -48px;

                                ${BREAKPOINT.lg} {
                                    left: -60px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.list-with-titles {
            > li {
                &::before {
                    left: -44px;
                    font-size: 28px;
                    line-height: 1.3;

                    ${BREAKPOINT.lg} {
                        left: -52px;
                        font-size: 34px;
                    }
                }

                > ol {
                    li {
                        ::before {
                            left: -50px;
                            font-size: 20px;

                            ${BREAKPOINT.lg} {
                                left: -64px;
                                font-size: 28px;
                            }
                        }

                        > ol {
                            li {
                                ::before {
                                    font-size: 16px;
                                    line-height: 1.3;

                                    ${BREAKPOINT.lg} {
                                        font-size: 18px;
                                        line-height: 1.5;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /* text-align */
    .has-text-align-left {
        text-align: left;
    }

    .has-text-align-center {
        text-align: center;
    }

    .has-text-align-right {
        text-align: right;
    }

    .has-large-font-size {
        margin: 16px 0;
        font-size: 18px;

        ${BREAKPOINT.lg} {
            font-size: 24px;
        }
    }

    .has-x-large-font-size {
        margin: 32px 0 16px;
        font-size: 24px;

        ${BREAKPOINT.lg} {
            margin: 40px 0 16px;
            font-size: 32px;
        }
    }

    /* table */
    .wp-block-table {
        margin: 0;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    table.pecunia span {
        display: block;
        text-align: right;
    }

    td {
        padding: 3px 9px;
        vertical-align: initial;
        border: 1px solid black;
    }

    *:last-child {
        margin-bottom: 0;
    }

    .download-pdf {
        display: flex;
        justify-content: end;
        font-size: 16px;

        ${BREAKPOINT.lg} {
            font-size: 18px;
        }

        svg {
            margin-right: 10px;
        }
    }
`;
