// libraries
import React from 'react';
import { graphql } from 'gatsby';
// components
import { SEO } from 'components/SEO';
import { GlobalGridVars } from 'components/UI/Grid';
import { GlobalStyles } from 'components/UI/GlobalStyles';
import { Document } from 'components/layouts/Document';
import { Layout } from 'components/Layout';

const PromotionsPage = ({ data }) => {
    return (
        <Layout>
            <GlobalStyles />
            <GlobalGridVars />
            <Document data={data.promotions} />
        </Layout>
    );
};
export default PromotionsPage;

export const Head = ({ data }) => {
    return <SEO data={data.promotions.seo} />;
};

export const query = graphql`
    query ($id: String!) {
        promotions: wpPromotions(id: { eq: $id }) {
            id
            title
            content
            seo {
                canonical
                metaDesc
                metaRobotsNofollow
                metaRobotsNoindex
                title
            }
        }
    }
`;
